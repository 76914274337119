import { libTypes } from "@holta/lib";
import {
    Container,
    Ellipsis,
    FormInput,
    FormLabel,
    FormTextarea,
    Grid,
    Icon,
    Island,
    IslandAlt,
    Menu,
    MenuItem,
    Modal,
    ModalBody,
    ModalHeader,
    NoResults,
    Spacer,
    Tab,
    Table,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useModal,
} from "@holta/ui";
import { fns } from "@holta/lib";
import styled from "styled-components";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { OrderItemsEditCarcaseRow } from "./OrderItemsEditCarcaseRow";
import { useStore } from "../../../config/store";
import * as editOrderActions from "../../../actions/editOrder";
import {useDialog} from "@holta/ui";
import { useDrag, useDrop } from 'react-dnd'
import React from 'react';
import { useOrderContext } from "../context";
import { OrderItemsSortDropArea } from "./OrderItemsSortDropArea";

export const OrderItemsCarcaseRow = ({
    item,
    index,
    isDraggingOver = false
}: {
    item: libTypes.CarcaseOrderItem;
    index: number;
    isDraggingOver?: boolean;
}) => {
    const ref = React.useRef(null)
    const editOrder = useMergedEditOrder();
    const editItem = useStore((state) => state.editOrder?.newValues?.orderItem);
    const {openGenericDialog} = useDialog();
    const {dragOrderItems} = useOrderContext();
    

    
    const [{ opacity }, drag] = useDrag(
        () => ({
            type: "orderItemRow",
            item: () => {
                dragOrderItems.setDragId(item.id);
                return {id: item.id}
            },
            collect: (monitor) => {
                return ({
                opacity: monitor.isDragging() ? 0.5 : 1,
            })},
            end: (item, monitor) => {
                dragOrderItems.setDragId(null);
                dragOrderItems.setHoverId(null);
            }
        }),
        []
    );
    drag(ref);
    function handleDelete() {
        openGenericDialog("Are you sure you want to remove this item?", "Delete Item", false, true, () => {editOrderActions.deleteOrderItem(item.id)})
    }

    const borderTop = dragOrderItems.dragId ? "1px solid #C2C6D0" : "none";

    if (!editOrder) return null;
    
    if (editItem && editItem.id === item.id && fns.order_item.isCarcaseItem(item)) {
        return (
            <OrderItemsEditCarcaseRow
                orderItem={
                    fns.order_item.merge(
                        item,
                        editItem as libTypes.EditedCarcaseOrderItem
                    ) as libTypes.CarcaseOrderItem
                }
            />
        );
    } else
        return (
            <>
                <Tr ref={ref} hasHover onClick={() => editOrderActions.editCarcaseItem(item.id)} style={{opacity, borderTop}}>
                    <Td colspan={3}>
                        <Ellipsis>
                            <Text size="m">{index}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={3}>
                        <Ellipsis>
                            <Text size="m">{item.quantity}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={3}>
                        <Ellipsis>
                            <Text size="m">{item.handing}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={8}>
                        <Ellipsis>
                            <Text size="m">{item.code}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={16}>
                        <Ellipsis>
                            <Text size="m">{item.description}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={8}>
                        <Ellipsis tooltipText={item.notes}>
                            <Text size="m">{item.notes}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={4}>
                        <Ellipsis>
                            {item.hEdited ? (
                                <Text size="m" bold>
                                    {item.hEdited}
                                </Text>
                            ) : (
                                <Text size="m">{item.h}</Text>
                            )}
                        </Ellipsis>
                    </Td>
                    <Td colspan={4}>
                        <Ellipsis>
                            {item.wEdited ? (
                                <Text size="m" bold>
                                    {item.wEdited}
                                </Text>
                            ) : (
                                <Text size="m">{item.w}</Text>
                            )}
                        </Ellipsis>
                    </Td>
                    <Td colspan={4}>
                        <Ellipsis>
                            {item.dEdited ? (
                                <Text size="m" bold>
                                    {item.dEdited}
                                </Text>
                            ) : (
                                <Text size="m">{item.d}</Text>
                            )}
                        </Ellipsis>
                    </Td>
                    <Td colspan={8}>
                        <Ellipsis>
                            <Text size="m">{fns.shared.formatPrice(item.price)}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={6}>
                        <Ellipsis>
                            <Text size="m">
                                {fns.shared.formatPrice(fns.order_item.getOrderItemTotal(item))}
                            </Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={2} align="c">
                        <div onClick={e => {e.preventDefault(); e.stopPropagation();}}>
                        <Menu button={<Icon name="menu" />}>
                            <MenuItem onClick={() => editOrderActions.duplicateOrderItem(item.id)}>
                                <Grid.Row>
                                    <Icon name="file-copy"></Icon>&nbsp;Duplicate
                                </Grid.Row>
                            </MenuItem>
                            <MenuItem onClick={handleDelete}>
                                <Grid.Row>
                                    <Icon name="delete-bin"></Icon>&nbsp;Delete
                                </Grid.Row>
                            </MenuItem>
                        </Menu>
                        </div>
                    </Td>
                </Tr>
                <OrderItemsSortDropArea id={item.id}/>
                </>
            
        );
};
