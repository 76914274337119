import {
    Container,
    FormDate,
    FormInput,
    FormLabel,
    FormSelect,
    FormTextarea,
    FormValue,
    Grid,
    Island,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    Tab,
    Tabs,
    Text,
    useModal,
    Menu,
    Popover,
    useDialog,
    Icon,
    IconButton,
} from "@holta/ui";
import React from "react";
import { useMenuButtonContext } from "@holta/ui";
import * as editOrderActions from "../../../actions/editOrder";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { fns, libTypes } from "@holta/lib";
import { hooks } from "@holta/ui";
import { useElementSize } from "usehooks-ts";
import { InputCarcaseColour } from "../../../components/InputCarcaseColour";
import { InputDrawerbox } from "../../../components/InputDrawerbox";
import { InputVoid } from "../../../components/InputVoid";
import { useModalAddressContext } from "../../../components/ModalAddress/context";

export const OrderDetails = () => {
    const editOrder = useMergedEditOrder();
    const [deliveryDateOpen, setDeliveryDateOpen] = hooks.useToggle(false);
    const [productionDateOpen, setProductionDateOpen] = hooks.useToggle(false);
    const [deliveryDateRef, deliveryDateSize] = useElementSize();
    const [productionDateRef, productionDateSize] = useElementSize();
    const { openGenericDialog } = useDialog();
    const {openModalAddress} = useModalAddressContext();

    if (!editOrder) return null;
    
    return (
        <>
            <hr />
            <Container xl="fixed">
            <Spacer xs={[1, 2, 2, 2]}>
                    <Spacer xs={[1]}>
                        <Text size="xl">Order Details</Text>
                    </Spacer>
                    <Grid.Row>
                        <Grid.Col xs={{ cols: 6 }}>
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Your Ref
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 8 }}>
                                        <FormInput
                                            style={{ width: "100%" }}
                                            value={editOrder.customerRef}
                                            onChange={(e) =>
                                                editOrderActions.customerRef(e.target.value)
                                            }
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            <Spacer xs={[0.25, 1]}>
                                <FormLabel type="contained">Delivery Notes</FormLabel>
                                <FormTextarea
                                    style={{ width: "100%" }}
                                    value={editOrder.deliveryNotes}
                                    onChange={(e) => editOrderActions.deliveryNotes(e.target.value)}
                                ></FormTextarea>
                            </Spacer>
                        </Grid.Col>

                        <Grid.Col xs={{ cols: 6 }}>
                        
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Preferred Del. Date
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col
                                        xs={{ cols: 8 }}
                                        style={{ width: "100%", display: "flex" }}
                                    >
                                        <FormDate
                                            onChange={(d) => d && !Array.isArray(d) && editOrderActions.deliveryDate(d.getTime())}
                                            dateFormat="dd/MM/yyyy"
                                            selected={editOrder.deliveryDate ? new Date(editOrder.deliveryDate) : undefined}
                                            showWeekNumbers
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            <Spacer xs={[0.25, 1]}>
                                <FormLabel type="contained">Delivery Address</FormLabel>
                                <FormValue
                                    style={{ width: "100%", 'height': '100px', overflow: 'auto' }}
                                    onClick={() => openModalAddress(editOrder.deliveryAddress, editOrderActions.deliveryAddress)}
                                >{fns.address.createAddressString(editOrder.deliveryAddress).split('\n').map((line, i) => <span key={i}>{line}<br/></span>)}</FormValue>
                            </Spacer>
                        </Grid.Col>
                    </Grid.Row>
                </Spacer>
            </Container>
        </>
    );
};
