import { Header, Text } from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { ProfileButton } from "./parts/ProfileButton";

export const AppHeader = () => {
    return (
        <S.Header>
            <Text el="h1" size="l" bold color="base_contrast">
                Holta Kitchen Orders
            </Text>{" "}
            <S.RightSide>
                <ProfileButton />
            </S.RightSide>
        </S.Header>
    );
};

const S = {
    RightSide: styled.div``,
    Header : styled(Header)`
        background-color: ${(p => p.theme.color.text)};    
    `
};
