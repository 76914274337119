import {
    Container,
    FormDate,
    FormInput,
    FormLabel,
    FormSelect,
    FormTextarea,
    FormValue,
    Grid,
    Island,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    Tab,
    Tabs,
    Text,
    useModal,
    Menu,
    Popover,
    useDialog,
    Icon,
    IconButton,
} from "@holta/ui";
import React from "react";
import { useMenuButtonContext } from "@holta/ui";
import * as editOrderActions from "../../../actions/editOrder";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { hooks } from "@holta/ui";
import { useElementSize } from "usehooks-ts";
import { InputCarcaseColour } from "../../../components/InputCarcaseColour";
import { InputGluedWallUnits } from "../../../components/InputGluedWallUnits";
import { InputDrawerbox } from "../../../components/InputDrawerbox";
import { InputConstructionType } from "../../../components/InputConstructionType";
import { InputVoid } from "../../../components/InputVoid";



export const OrderConstruction = () => {
    const { openModal } = useModal();
    const editOrder = useMergedEditOrder();
    const handleCustomerWarningCB = React.useRef(() => {});
    const [deliveryDateOpen, setDeliveryDateOpen] = hooks.useToggle(false);
    const [productionDateOpen, setProductionDateOpen] = hooks.useToggle(false);
    const [deliveryDateRef, deliveryDateSize] = useElementSize();
    const [productionDateRef, productionDateSize] = useElementSize();
    const { openGenericDialog } = useDialog();

    if (!editOrder) return null;

    return (
        <>
            <hr />
            <Container xl="fixed">
            <Spacer xs={[1, 2, 2, 2]}>
                    <Spacer xs={[1]}>
                        <Text size="xl">Construction</Text>
                    </Spacer>
                    <Grid.Row>
                        <Grid.Col xs={{ cols: 6 }}>
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Carcase Colour
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 8 }}>
                                        <InputCarcaseColour value={editOrder.carcaseColour} onChange={editOrderActions.carcaseColour}/>
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Drawerbox
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 8 }}>
                                        <InputDrawerbox value={editOrder.drawerbox} onChange={editOrderActions.drawerbox}/>
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Void
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 8 }}>
                                        <InputVoid value={editOrder.voidSize} onChange={editOrderActions.voidSize}/>
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                        </Grid.Col>

                        <Grid.Col xs={{ cols: 6 }}>
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Glued Wall Units
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 8 }}>
                                        <InputGluedWallUnits value={editOrder.gluedWallUnits} onChange={editOrderActions.gluedWallUnits}/>
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Construction Type
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 8 }}>
                                        <InputConstructionType value={editOrder.constructionType} onChange={editOrderActions.constructionType}/>
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            
                        </Grid.Col>
                    </Grid.Row>
                </Spacer>
            </Container>
            
        </>
    );
};
