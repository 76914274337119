import React from 'react';
import {feathersApp} from '../config/feathers';
import {setProducts} from '../actions/products';
import { libTypes } from '@holta/lib';



export const useLoadProducts = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    feathersApp.service('products').watch().find({query: {
        $limit: 100000,
        $sort: {
            code: 1,
        }
    }}).subscribe(({data} : {data: libTypes.Product[]}) => setProducts(data));
    return isLoaded
}
