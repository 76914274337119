import { useModal } from "@holta/ui";

import { useMatch, usePathParams } from "raviger";
import React from "react";
import { createNewOrder, clearAll, loadOrderFromLocalStorage } from "../../../actions/editOrder";
import { useStore } from "../../../config/store";

export const useInitializeOrder = () => {
    const { closeAll } = useModal();
    const editOrder = useStore((state) => state.editOrder);

    React.useEffect(() => {
        const ls = JSON.parse(localStorage.getItem("editOrder") || "{}");
        if(ls && Object.keys(ls).length > 0) loadOrderFromLocalStorage();
        else createNewOrder();
    }, []);


    if (!editOrder) return null;
    return editOrder;
};
