import {
    Container,
    FormInput,
    FormLabel,
    FormTextarea,
    Grid,
    IconButton,
    Island,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    Tab,
    Tabs,
    Text,
    useModal,
} from "@holta/ui";
import { fns } from "@holta/lib";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { useOrderContext } from "../context";

export const OrderTotals = () => {
    const editOrder = useMergedEditOrder();
    if (!editOrder) return null;

    return (
        <>
            <hr />
            <Container xl="fixed">
                <Spacer xs={[3, 1]}>
                    <Grid.Row>
                        <Grid.Col xs={{ cols: 6, offset: 6 }}>
                            <Spacer xs={[1]}>
                                <Island>
                                   

                                    <Grid.Row xs={{ justify: "space-between" }}>
                                        <Text size="l" bold>
                                            Order Total (ex VAT)
                                        </Text>
                                        <Text size="m" bold>
                                        {fns.shared.formatPrice(fns.order.getTotal(editOrder))}
                                        </Text>
                                    </Grid.Row>
                                    <Spacer xs={[1]} />
                                    <Grid.Row xs={{ justify: "space-between" }}>
                                        <Text size="m" >
                                        Note: this total is an estimate only and does not include any discount.
                                        </Text>
                                    </Grid.Row>
                                </Island>
                            </Spacer>
                        </Grid.Col>
                    </Grid.Row>
                </Spacer>
            </Container>
        </>
    );
};
