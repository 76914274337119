import * as editOrderActions from "../../../actions/editOrder";
import { useDrop } from "react-dnd";
import React from "react";
import { useOrderContext } from "../context";



export const OrderItemsSortDropArea = ({ id }: { id: string | 0 }) => {
    const { dragOrderItems } = useOrderContext();
    const ref = React.useRef(null);
    const [collectedProps, drop] = useDrop(() => ({
        accept: "orderItemRow",
        drop: (dragItem, monitor) => {
            editOrderActions.updateOrderItemSortOrder((dragItem as any).id, id);
        },
        hover: (dragItem, monitor) => {
            if (dragOrderItems.hoverId !== id) {
                dragOrderItems.setHoverId(id);
            }
        },
        collect(monitor) {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            };
        },
    }));
    drop(ref);
    const height = dragOrderItems.dragId ? "10px" : "0px";
    const color = collectedProps.canDrop && collectedProps.isOver ? "blue" : "transparent";
    return (
        <div
            ref={ref}
            style={{
                position: "relative",
                height: height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    position: "absolute",
                    top: "4px",
                    left: "4px",
                    right: "4px",
                    bottom: "4px",
                    backgroundColor: color,
                    borderRadius: "4px",
                }}
            />
        </div>
    );
};
