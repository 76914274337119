import { fns, libTypes } from "@holta/lib";
import { Table, Thead, Tr, Th, Tbody } from "../../table";
import { VirtualisedTable } from "../../virtualisedTable";
import { Spacer } from "../../spacer";
import { Ellipsis } from "../../ellipsis";
import { Text } from "../../text";
import React from "react";
import {ProductsTableRow} from './ProductsRow';
import { useProductBrowserContext } from "../context";


export const ProductsTable = () => {
    const { filteredProducts } = useProductBrowserContext()

    return (
        <Spacer
            xs={[2, 2, 2, 2]}
            style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}
        >
            <Table cols={15.1}>
                <Thead>
                    <Tr>
                        <Th colspan={3} minWidth="50px" >
                            Code
                        </Th>
                        <Th colspan={4}>Description</Th>
                        <Th colspan={1} align="c">
                            H
                        </Th>
                        <Th colspan={1} align="c">
                            W
                        </Th>
                        <Th colspan={1} align="c">D</Th>
                        <Th colspan={4}>Spec</Th>
                        <Th colspan={0.1} >Img</Th>
                    </Tr>
                </Thead>
                <Tbody style={{ flex: 1 }}>
                    <VirtualisedTable Row={ProductsTableRow} items={filteredProducts} rowHeight={38} />
                </Tbody>
            </Table>
        </Spacer>
    );
};
