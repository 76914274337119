import { FormSelect } from "@holta/ui";
import { staticData, fns, libTypes } from "@holta/lib";

const options = fns.shared.formSelect.createOptions(
    "key",
    "label",
    Object.values(staticData.voidSizes)
);

export const InputVoid = ({
    value,
    onChange,
}: {
    value: libTypes.EditedOrderValues["voidSize"];
    onChange: (value: libTypes.VoidSize) => void;
}) => {
    const selected = value
        ? fns.shared.formSelect.createValueOption(
              value,
              "key",
              "label",
              Object.values(staticData.voidSizes)
          )
        : null;

    return (
        <FormSelect
            isSearchable={false}
            options={options}
            style={{ width: "100%" }}
            onChange={(val: { value: libTypes.VoidSize }) => {
                onChange(val.value);
            }}
            value={selected}
        />
    );
};
