import { Spacer } from "../../spacer";
import React from "react";
import {useProductBrowserContext} from "../context";
import { FormInput } from "../../form";
import { Grid } from "../../grid";

export const FilterInput = () => {
    const { filter, setFilter } = useProductBrowserContext();

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value.trim());
    };

    const handleClear = () => {
        setFilter('');
    };

    return (
        <Spacer xs={[2, 2, 0, 2]}>
            <Grid.Row>
                <FormInput value={filter} onChange={handleFilterChange} placeholder="Filter products" style={{width: '100%', textAlign: 'left'}} />
                <Spacer xs={[0.5]}></Spacer>
                <button onClick={handleClear}>Clear</button>
            </Grid.Row>
        </Spacer>
    );
};