import { fns, libTypes } from "@holta/lib";
import { useDialog, useModal } from "@holta/ui";
import React from "react";

export const ModalAddressContext =
    React.createContext<
        | {
              address: libTypes.AddressFields | null;
              openModalAddress: (
                  address: libTypes.AddressFields,
                  handleConfirm: (address: libTypes.AddressFields) => void,
                  handleCancel?: () => void
              ) => void;
              setAddressLine1: (addressLine1: string) => void;
              setAddressLine2: (addressLine2: string) => void;
              setAddressLine3: (addressLine3: string) => void;
              setCity: (city: string) => void;
              setCountry: (country: string) => void;
              setCounty: (county: string) => void;
              setPostcode: (postcode: string) => void;
              cancel: () => void;
              confirm: () => void;
          }
        | undefined
    >(undefined);

export const useModalAddressContext = () => {
    const context = React.useContext(ModalAddressContext);
    if (context === undefined) {
        throw new Error("useModalAddressContext must be within the correct provider");
    }
    return context;
};

export const ModalAddressContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [address, setAddress] = React.useState<libTypes.AddressFields | null>(null);
    const { openModal, closeModal } = useModal();
    const { openGenericDialog } = useDialog();

    const handleConfirmFn = React.useRef<(address: libTypes.AddressFields) => void>();
    const handleCancelFn = React.useRef<() => void>();

    const setAddressLine1 = (setAddressLine1: string) => {
        address && setAddress(fns.address.set.addressLine1(address, setAddressLine1));
    };
    const setAddressLine2 = (setAddressLine2: string) => {
        address && setAddress(fns.address.set.addressLine2(address, setAddressLine2));
    };
    const setAddressLine3 = (setAddressLine3: string) => {
        address && setAddress(fns.address.set.addressLine3(address, setAddressLine3));
    };

    const setCity = (city: string) => {
        address && setAddress(fns.address.set.city(address, city));
    };

    const setCountry = (country: string) => {
        address && setAddress(fns.address.set.country(address, country));
    };

    const setCounty = (county: string) => {
        address && setAddress(fns.address.set.county(address, county));
    };


    const setPostcode = (postcode: string) => {
        address && setAddress(fns.address.set.postcode(address, postcode));
    };

    const wrappedOpenModal = (
        address: libTypes.AddressFields,

        handleConfirm: (address: libTypes.AddressFields) => void,

        handleCancel?: () => void
    ) => {
        setAddress(address);
        handleConfirmFn.current = handleConfirm;
        openModal("MODAL_ADDRESS");

        handleCancelFn.current = handleCancel;
    };

    const cancel = () => {
        handleCancelFn.current && handleCancelFn.current();
        closeModal("MODAL_ADDRESS");
    };

    const confirm = () => {
        if (!address) {
            closeModal("MODAL_ADDRESS");
            return;
        }
        handleConfirmFn.current && address && handleConfirmFn.current(address);
        closeModal("MODAL_ADDRESS");
    };

    return (
        <ModalAddressContext.Provider
            value={{
                address,
                openModalAddress: wrappedOpenModal,
                setAddressLine1,
                setAddressLine2,
                setAddressLine3,
                setCity,
                setCountry,
                setCounty,
                setPostcode,
                cancel,
                confirm,
            }}
        >
            {children}
        </ModalAddressContext.Provider>
    );
};
