import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormToggle,
    FormTextarea,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { useModalAddressContext } from "./context";

interface Props {
    children?: React.ReactNode;
}

export const ModalAddress: React.FC<Props> = ({ children }) => {

    const [tab, setTab] = React.useState(0);
    const {
        address,
        setAddressLine1,
        setAddressLine2,
        setAddressLine3,
        setCity,
        setCountry,
        setCounty,
        setPostcode,
        cancel,
        confirm,
    } = useModalAddressContext();

    if (!address) {
        return null;
    }
    return (
        <Modal id="MODAL_ADDRESS" style={{ width: "600px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                    <div>
                        <Grid.Row xs={{ align: "center" }}>Address</Grid.Row>
                    </div>
                </ModalHeader>
                
                <ModalBody>
                    <Spacer xs={[2]}>
                        {tab === 0 && (
                            <Island>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Address Line 1</FormLabel>

                                    <FormInput
                                        value={address.addressLine1}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setAddressLine1(e.target.value)}
                                    />
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Address Line 2</FormLabel>
                                    <FormInput
                                        value={address.addressLine2}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setAddressLine2(e.target.value)}
                                    />
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Address Line 3</FormLabel>

                                    <FormInput
                                        value={address.addressLine3}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setAddressLine3(e.target.value)}
                                    />
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>City</FormLabel>

                                    <FormInput
                                        value={address.city}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </Spacer>

                                <Spacer xs={[1, 0]}>
                                    <FormLabel>County</FormLabel>

                                    <FormInput
                                        value={address.county}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setCounty(e.target.value)}
                                    />
                                </Spacer>

                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Postcode</FormLabel>

                                    <FormInput
                                        value={address.postcode}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setPostcode(e.target.value)}
                                    />
                                </Spacer>

                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Country</FormLabel>

                                    <FormInput
                                        value={address.country}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setCountry(e.target.value)}
                                    />
                                </Spacer>
                            </Island>
                        )}
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    <button onClick={cancel}> {"Cancel"}</button>

                    <Spacer xs={[0.5]} />

                    <button className="primary" onClick={confirm}>
                        Save
                    </button>
                </ModalFooter>
            </>
        </Modal>
    );
};
