import {
    Tr,
    Td,
    Text,
    Ellipsis,
    Icon
} from "../../../index";
import React from "react";
import { fns } from "@holta/lib";
import { libTypes } from "@holta/lib";
import { useProductBrowserContext } from "../context";
import styled from "styled-components";

export const ProductsTableRow = ({
    style,
    index,
    data,
}: {
    style: React.CSSProperties;
    index: number;
    data: libTypes.Product[];
}) => {
    const product = data[index];

    const { selectedProduct, setSelectedProduct, setImgHover } = useProductBrowserContext()


    return (
        <Tr hasHover style={style} isSelected={selectedProduct?.id === product.id} onClick={() => setSelectedProduct(product)}>
            <Td colspan={3} spacing={0.5}>
                <Ellipsis tooltipText={product.code}><Text size="s">{product.code}</Text></Ellipsis>
            </Td>
            <Td colspan={4}  spacing={0.5}>
                <Ellipsis tooltipText={product.description}> <Text size="s">{product.description}</Text></Ellipsis>
            </Td>
            <Td colspan={1} align="c"  spacing={0.5}>
                <Ellipsis tooltipText={String(product.h)}> <Text size="s">{product.h}</Text></Ellipsis>
            </Td>
            <Td colspan={1} align="c"  spacing={0.5}>
                <Ellipsis tooltipText={String(product.w)}> <Text size="s">{product.w}</Text></Ellipsis>
            </Td>
            <Td colspan={1} align="c"  spacing={0.5}>
                <Ellipsis tooltipText={String(product.d)}> <Text size="s">{product.d}</Text></Ellipsis>
            </Td>
            <Td colspan={4}  spacing={0.5}>
                <Ellipsis tooltipText={product.specification}> <Text size="s">{product.specification}</Text></Ellipsis>
            </Td>
            <Td colspan={1}  spacing={0.5}>
                {product.image && 
                <Icon name="image" onMouseEnter={()=> setImgHover(product.image)} onMouseLeave={() => setImgHover(false)} />
}
            </Td>
        </Tr>
    );
};

