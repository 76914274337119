import React from 'react';
import {feathersApp} from '../config/feathers';
import {setProductComponents} from '../actions/productComponents';
import { libTypes } from '@holta/lib';



export const useLoadProductComponents = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    feathersApp.service('product-components').watch().find({query: {
        $limit: 10000
      }}).subscribe(({data} : {data: libTypes.ProductComponent[]}) => setProductComponents(data));
    return isLoaded
}
