
import {FormSelect} from '@holta/ui';
import {staticData, fns, libTypes} from '@holta/lib';


const options = staticData.drawerboxTypes.map((drawerbox) => ({
    label: drawerbox,
    value: drawerbox,
})).sort((a, b) => a.label.localeCompare(b.label));

export const InputDrawerbox = ({value, onChange } : {value : libTypes.EditedOrderValues['drawerbox'], onChange: (value: libTypes.DrawerboxType) => void}) => {

    const selected = value ? {label: value, value: value} : null;

    return (
        <FormSelect options={options} style={{ width: "100%" }} onChange={(val : {value : libTypes.DrawerboxType}) => {onChange(val.value)}} value={selected}/>
    )
}