
import {FormSelect} from '@holta/ui';
import {staticData, fns, libTypes} from '@holta/lib';


const options = staticData.mfcColours.map((colour) => ({
    label:  colour.code + ' - ' + colour.texture + ' - ' + colour.decor_name,
    value: colour,
})).sort((a, b) => a.label.localeCompare(b.label))

export const InputCarcaseColour = ({value, onChange } : {value : libTypes.EditedOrderValues['carcaseColour'], onChange: (value: libTypes.MFCColour) => void}) => {



    const selected = value?.code && value?.texture ? {label: value.decor_name + ' ' + value.texture, value: value} : null;

    return (
        <FormSelect options={options} style={{ width: "100%" }} onChange={(val : {value : libTypes.MFCColour}) => {onChange(val.value)}} value={selected}/>
    )
}