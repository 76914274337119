import { fns, libTypes } from "@holta/lib";
import { feathersApp } from "../config/feathers";
import { createNewOrder, getMergedOrderFromStore } from "./editOrder";
import { user } from "../fns/user";

export const sendOrder = () => {

    try {
        const order = getMergedOrderFromStore();
    
        const customerConvertedOrder = {
            customerEmail: user.email,
            customerRef: order.customerRef,
            deliveryDate: order.deliveryDate,
            deliveryDateType: order.deliveryDateType,
            deliveryAddress: order.deliveryAddress,
            deliveryNotes: order.deliveryNotes,
            carcaseColour: order.carcaseColour,
            constructionType: order.constructionType,
            drawerbox: order.drawerbox,
            voidSize: order.voidSize,
            gluedWallUnits: order.gluedWallUnits,
            items: order.items.filter(fns.order_item.isCarcaseItem).map((item) => {
                return {
                    productId: item.productId,
                    quantity: item.quantity,
                    hEdited: item.hEdited,
                    wEdited: item.wEdited,
                    dEdited: item.dEdited,
                    handing: item.handing,
                    notes: item.notes,
                };
            }),
        };
    
        return feathersApp.service("actions").create({
            action: "CUSTOMER_SEND_KITCHEN_ORDER",
            order: customerConvertedOrder}).then(() => {
                createNewOrder();
                alert('Order sent!')
            });
    } catch (error) {
        return error;
    }
  
};
