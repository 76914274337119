import {create} from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import React from "react";
import { libTypes } from "@holta/lib";
import { sub, startOfISOWeek, endOfISOWeek } from "date-fns";

type EditedProps = { _deleted?: boolean; _dbUnsaved?: boolean };

export type EditedAddress = libTypes.Address & EditedProps;
export type EditedContact = libTypes.Contact & EditedProps;
export type EditedSupplierRange = libTypes.SupplierRange & EditedProps;
export type EditedBaseProduct = libTypes.SupplierBaseProduct & EditedProps;
export type EditedSupplierProduct = libTypes.SupplierProduct & EditedProps;

export type StoreEditOrder = {
    type: "NEW" | "EDIT";
    newValues: {
        order: libTypes.EditedOrderValues;
        orderItem: Partial<libTypes.EditedOrderItem>;
    };
    currentValues: {
        order: libTypes.Order;
        supplierDoorRange: libTypes.SupplierComputedProduct[];
    };
};


export type StoreEditSupplier = {
    type: "NEW" | "EDIT";
    newValues: {
        supplier: Partial<libTypes.Supplier>;
        addresses: EditedAddress[];
        contacts: EditedContact[];
        ranges: EditedSupplierRange[];
        baseProducts: EditedBaseProduct[];
        products: EditedSupplierProduct[];
    };
    currentValues: {
        supplier: libTypes.Supplier;
        addresses: libTypes.Address[];
        contacts: libTypes.Contact[];
        ranges: libTypes.SupplierRange[];
        baseProducts: libTypes.SupplierBaseProduct[];
        products: libTypes.SupplierProduct[];
    };
};

export type AdminStore = {
    editOrder: StoreEditOrder | null;
    categories: libTypes.Category[];
    products: libTypes.Product[];
    productComponents: libTypes.ProductComponent[];
    ui: {
        
    };
};

export const useStore = create<AdminStore>(() => ({
    editOrder: null,
    categories: [],
    products: [],
    productComponents: [],
    ui: {},
}));
