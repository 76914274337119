import { Island, Spacer, useModal, ProductBrowser, Grid, useDialog } from "@holta/ui";
import { OrderDetails } from "./parts/OrderDetails";
import { OrderItems } from "./parts/OrderItems";
import { OrderContextProvider } from "./context";
import { OrderConstruction } from "./parts/OrderConstruction";
import React from "react";
import {
    createCarcaseItem,
    updateCarcaseItemCode,
    saveEditOrderItem,
    createNewOrder
} from "../../actions/editOrder";
import { useStore } from "../../config/store";
import { ModalAddress } from "../../components/ModalAddress";
import { useInitializeOrder } from "./hooks/useInitializeOrder";
import { sendOrder } from "../../actions/order";
import { user } from "../../fns/user";
import { OrderTotals } from "./parts/OrderTotals";
import { useMergedEditOrder } from "./hooks/useMergedEditOrder";

export const Order = () => {
    const editOrder = useInitializeOrder();
    const products = useStore((state) => state.products);
    const categories = useStore((state) => state.categories);
    const {openGenericDialog} = useDialog();
    const { closeModal } = useModal();
    const order = useMergedEditOrder();
    const [hasError, setHasError] = React.useState(false);

    const wrappedSendOrder = () => {
        try {
            sendOrder().then().catch(() => {
                
                    openGenericDialog(`There was an error sending the order - Please try again. 
            
                    If the problem persists, try refreshing your browser or contact Holta with a screenshot of your order.`, "Error", false, false, () => { }, false, 'ERRORSENDING');
                
               
            });
                
        }
        catch (e) {
            
                openGenericDialog(`There was an error sending the order. - Please try again. 
        
                If the problem persists, try refreshing your browser or contact Holta with a screenshot of your order.`, "Error", false, false, () => { }, false, 'ERRORSENDING');
            
        }
    }

    const handleSendOrder = () => {
        if(!order) return;
        
            
                openGenericDialog(`A copy of this order will be sent to Holta.
    
                This order will be checked and returned to you for confirmation.
                
                Please note - Manufacture will not begin until the order is confirmed `, 'Send the order', true, true, wrappedSendOrder, true);
            
        
        
    }

    const clearOrder = () => {
        openGenericDialog('Confirm clear order?', 'Clear Order', false, true, createNewOrder, false);
    }

    if (!editOrder) return null;

    return (
        <OrderContextProvider>
            <div style={{ width: "100%" }}>
                <Spacer xs={[2, 2, 0, 2]}>
                    <Grid.Row xs={{justify: 'end'}}>
                    <Grid.Col><button className="tertiary" onClick={clearOrder}>Clear Order</button></Grid.Col>
                        <Spacer xs={[1]}></Spacer>
                        <Grid.Col><button className="primary" onClick={handleSendOrder}>Send Order</button></Grid.Col>
                    </Grid.Row>
                    </Spacer>
                <Spacer xs={[2, 2, 6, 2]}>
                    
                    <Island p={0}>
                        <OrderDetails />
                        <OrderConstruction />
                        <OrderItems />
                        <OrderTotals />
                    </Island>
                </Spacer>
                <ProductBrowser
                    modalId={"PRODUCT_BROWSER"}
                    products={products}
                    categories={categories}
                    handleConfirm={(product) => {
                        closeModal("PRODUCT_BROWSER");
                        createCarcaseItem();
                        updateCarcaseItemCode(product.id);
                        saveEditOrderItem();
                    }}
                />
                <ModalAddress />
            </div>
        </OrderContextProvider>
    );
};
