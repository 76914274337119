import {
    Container,
    Ellipsis,
    FormInput,
    FormLabel,
    FormTextarea,
    Grid,
    Icon,
    Island,
    IslandAlt,
    Menu,
    MenuItem,
    Modal,
    ModalBody,
    ModalHeader,
    NoResults,
    Spacer,
    Tab,
    Table,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useModal,
} from "@holta/ui";
import styled from "styled-components";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";

export const OrderItemsEmptyRow = () => {
    const editOrder = useMergedEditOrder();

    if (!editOrder) return null;
    return <Tr>
        <Td colspan={100}>
            <div style={{textAlign: "center", width: '100%'}}><Text size="s">No Items</Text></div>
        </Td>
    </Tr>;
};

