import {
    Container,
    Ellipsis,
    FormInput,
    FormLabel,
    FormNumber,
    FormTextarea,
    Grid,
    Icon,
    Island,
    IslandAlt,
    Menu,
    MenuItem,
    Modal,
    ModalBody,
    ModalHeader,
    NoResults,
    Spacer,
    Tab,
    Table,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useModal,
} from "@holta/ui";
import styled from "styled-components";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { libTypes } from "@holta/lib";
import { InputProductSelect } from "../../../components/InputProductSelect";
import * as editOrderActions from "../../../actions/editOrder";
import { InputHandingSelect } from "../../../components/InputHandingSelect";
import { useTabs } from "../../../hooks/useTabs";
import React from "react";
import FocusLock from "react-focus-lock";

interface Props {
    orderItem: libTypes.EditedCarcaseOrderItem;
}

export const OrderItemsEditCarcaseRow = ({ orderItem }: Props) => {
    const editOrder = useMergedEditOrder();
    const firstInputRef = React.useRef<HTMLInputElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const tabs = useTabs("basic");

    React.useEffect(() => {
        setTimeout(() => {
            if (!containerRef.current) return;
            if(containerRef.current.getBoundingClientRect().bottom < (window.innerHeight - 80)) return;
            containerRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }, 300);
    }, [orderItem.code]);

    const wrappedSaveAndAddAnother = () => {
        editOrderActions.saveAndAddNewCarcaseItem();
        firstInputRef.current?.focus();
    };

    if (!editOrder) return null;
    return (
        <Tr ref={containerRef} style={{ scrollPaddingBottom: "40px" }}>
            
                <div
                    style={{
                        width: "calc(100% + 16px)",
                        backgroundColor: "white",
                        margin: "-1px -8px",
                    }}
                >
                    <EditingRow>
                    <FocusLock>
                            <Grid.Row>
                                <Grid.Col xs={{ cols: 6 }}>
                                    <Spacer xs={[2, 1, 2, 2]}>
                                        <Spacer xs={[0.25, 0]}>
                                            <Grid.Row>
                                                <Grid.Col xs={{ cols: 4 }}>
                                                    <FormLabel type="contained" position="left">
                                                        Item Code
                                                    </FormLabel>
                                                </Grid.Col>
                                                <Grid.Col xs={{ cols: 8 }}>
                                                    <InputProductSelect
                                                        value={{
                                                            code: orderItem.code,
                                                            productId: orderItem.productId,
                                                        }}
                                                        ref={firstInputRef}
                                                        onChange={(option: { value: string }) =>
                                                            editOrderActions.updateCarcaseItemCode(
                                                                option.value
                                                            )
                                                        }
                                                    />
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Spacer>
                                        <Spacer xs={[0.25, 0]}>
                                            <Grid.Row>
                                                <Grid.Col xs={{ cols: 4 }}>
                                                    <FormLabel type="contained" position="left">
                                                        Quantity
                                                    </FormLabel>
                                                </Grid.Col>
                                                <Grid.Col xs={{ cols: 8 }}>
                                                    <FormNumber
                                                        onChange={(e) =>
                                                            editOrderActions.updateCarcaseItemQuantity(
                                                                Number(e.target.value)
                                                            )
                                                        }
                                                        disabled={!orderItem.code}
                                                        isFloat
                                                        placeholder=""
                                                        value={orderItem.quantity}
                                                        style={{ width: "100%" }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Spacer>
                                        <Grid.Row>
                                            <Spacer xs={[0.25, 0]}>
                                                <Grid.Row>
                                                    <Grid.Col xs={{ cols: 4 }}>
                                                        <FormLabel type="contained" position="left">
                                                            H
                                                        </FormLabel>
                                                    </Grid.Col>
                                                    <Grid.Col xs={{ cols: 8 }}>
                                                        <FormNumber
                                                            onChange={(e) =>
                                                                editOrderActions.updateCarcaseItemHeight(
                                                                    Number(e.target.value)
                                                                )
                                                            }
                                                            disabled={!orderItem.code}
                                                            isFloat
                                                            placeholder=""
                                                            value={
                                                                orderItem.hEdited
                                                                    ? orderItem.hEdited
                                                                    : orderItem.h || ""
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Row>
                                            </Spacer>

                                            <Spacer xs={[0.25, 0]}>
                                                <Grid.Row>
                                                    <Grid.Col xs={{ cols: 4 }}>
                                                        <FormLabel type="contained" position="left">
                                                            W
                                                        </FormLabel>
                                                    </Grid.Col>
                                                    <Grid.Col xs={{ cols: 8 }}>
                                                        <FormNumber
                                                            onChange={(e) =>
                                                                editOrderActions.updateCarcaseItemWidth(
                                                                    Number(e.target.value)
                                                                )
                                                            }
                                                            disabled={!orderItem.code}
                                                            isFloat
                                                            placeholder=""
                                                            value={
                                                                orderItem.wEdited
                                                                    ? orderItem.wEdited
                                                                    : orderItem.w || ""
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Row>
                                            </Spacer>
                                            <Spacer xs={[0.25, 0]}>
                                                <Grid.Row>
                                                    <Grid.Col xs={{ cols: 4 }}>
                                                        <FormLabel type="contained" position="left">
                                                            D
                                                        </FormLabel>
                                                    </Grid.Col>
                                                    <Grid.Col xs={{ cols: 8 }}>
                                                        <FormNumber
                                                            onChange={(e) =>
                                                                editOrderActions.updateCarcaseItemDepth(
                                                                    Number(e.target.value)
                                                                )
                                                            }
                                                            disabled={!orderItem.code}
                                                            isFloat
                                                            placeholder=""
                                                            value={
                                                                orderItem.dEdited
                                                                    ? orderItem.dEdited
                                                                    : orderItem.d || ""
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Row>
                                            </Spacer>
                                        </Grid.Row>
                                        <Spacer xs={[0.25, 0]}>
                                            <Grid.Row>
                                                <Grid.Col xs={{ cols: 4 }}>
                                                    <FormLabel type="contained" position="left">
                                                        Handing
                                                    </FormLabel>
                                                </Grid.Col>
                                                <Grid.Col xs={{ cols: 8 }}>
                                                    <InputHandingSelect
                                                        isDisabled={!orderItem.code}
                                                        value={orderItem.handing}
                                                        onChange={(option: {
                                                            value: libTypes.Handing;
                                                        }) =>
                                                            editOrderActions.updateCarcaseItemHanding(
                                                                option.value
                                                            )
                                                        }
                                                    />
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Spacer>
                                    </Spacer>
                                </Grid.Col>
                                <Grid.Col xs={{ cols: 6 }}>
                                    <Spacer xs={[2, 2, 2, 1]}>
                                        <Spacer xs={[0.25, 0]}>
                                            <FormLabel type="contained">Description</FormLabel>
                                            <FormTextarea
                                                style={{ width: "100%" }}
                                                disabled={!orderItem.code}
                                                value={orderItem.description}
                                               
                                            ></FormTextarea>
                                        </Spacer>
                                        <Spacer xs={[0.25, 0]}>
                                            <Grid.Row>
                                                <Grid.Col xs={{ cols: 4 }}>
                                                    <FormLabel type="contained" position="left">
                                                        Specification
                                                    </FormLabel>
                                                </Grid.Col>
                                                <Grid.Col xs={{ cols: 8 }}>
                                                    <FormInput
                                                        disabled
                                                        value={orderItem.specification}
                                                        style={{ width: "100%" }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Spacer>
                                        <Spacer xs={[0.25, 0]}>
                                            <FormLabel type="contained">Notes</FormLabel>
                                            <FormTextarea
                                                style={{ width: "100%" }}
                                                disabled={!orderItem.code}
                                                value={orderItem.notes}
                                                onChange={(e) =>
                                                    editOrderActions.updateCarcaseItemNotes(
                                                        e.target.value
                                                    )
                                                }
                                            ></FormTextarea>
                                        </Spacer>
                                    </Spacer>
                                </Grid.Col>
                            </Grid.Row>
                        

                        <Spacer xs={[2]}>
                            <Grid.Row xs={{ justify: "end" }}>
                                <button onClick={editOrderActions.cancelEditOrderItem}>
                                    Cancel
                                </button>
                                <Spacer xs={[0.25]}></Spacer>
                                <button
                                    disabled={!orderItem.code}
                                    onClick={editOrderActions.saveEditOrderItem}
                                >
                                    Save
                                </button>
                                <Spacer xs={[0.25]}></Spacer>
                                <button
                                    className={orderItem.code && "primary"}
                                    disabled={!orderItem.code}
                                    onClick={wrappedSaveAndAddAnother}
                                >
                                    Save & add another
                                </button>
                            </Grid.Row>
                        </Spacer>
                        </FocusLock>
                    </EditingRow>
                </div>
            
        </Tr>
    );
};

const EditingRow = styled.div`
    border: 1px solid ${(props) => props.theme.color.highlight};
    border-radius: 4px;
    box-shadow: 0px 4px 15px 5px #cccbcb;
`;
