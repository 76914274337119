import React from 'react';
import {feathersApp} from '../config/feathers';
import {setcategories} from '../actions/categories';
import { libTypes } from '@holta/lib';



export const useLoadcategories = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    feathersApp.service('categories').watch().find({query: {
        $limit: 100000
    }}).subscribe(({data} : {data: libTypes.Category[]}) => setcategories(data));
    return isLoaded
}
